@charset "UTF-8";
html,
body,
#root,
.App {
  height: 100%;
}

body {
  -webkit-text-size-adjust: none !important;
}

#true {
  opacity: 0;
}

#loading-span-true {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  border: 12px solid #b9cbfa;
  /* Light grey */
  border-top: 12px solid #1652f0;
  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    border-top: 12px solid #1652f0;
  }
  50% {
    transform: rotate(180deg);
    border-top: 12px solid #b9cbfa;
  }
  100% {
    transform: rotate(360deg);
    border-top: 12px solid #1652f0;
  }
}

#loading-span-false {
  display: none;
}

#refresh-span-true {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #e7edfd;
  text-align: center;
  color: black;
  border: 2px solid #1652f0;
  border-radius: 1%;
  width: 25%;
  height: 20%;
  z-index: 1;
}

#refresh-span-true button {
  font-size: 1em;
  font-weight: bold;
  width: 45%;
  height: 25%;
}

#refresh-span-true i {
  position: absolute;
  height: 15%;
  width: 15%;
  right: 0;
  top: 0;
  color: red;
}

#refresh-span-true i:hover {
  color: darkred;
}

#refresh-span-true i:active {
  color: #5e0000;
}

#refresh-span-false {
  display: none;
}

#graph-container {
  width: 100%;
  height: 100%;
}

#item-page-container {
  display: flex;
}

#item-page-container #stats-cal-container {
  width: 50%;
}

input::placeholder {
  color: #b9cbfa;
}

header {
  width: 100%;
  height: 8%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  align-items: center;
}

header h1 {
  margin: 0;
  color: black;
}

header a {
  text-decoration: none;
}

header form {
  margin-left: 1.5em;
}

header i {
  margin: auto;
  margin-right: 5px;
  transition: color 0.25s ease;
}

header i:hover {
  cursor: pointer;
}

#navbar-left {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

#navbar-left a {
  display: flex;
  align-items: center;
  width: 200px;
}

#navbar-left a:hover {
  opacity: 0.75;
}

#navbar-left #logo {
  font-size: 2rem;
  margin: 0 5px;
  height: 1.5em;
  width: 1.5em;
}

#navbar-left #name-logo {
  width: 75%;
  height: 75%;
  margin: 0 5px;
}

#navbar-left #input-btn {
  background-color: #1652f0;
  color: #fff;
  border: none;
  border-radius: 10%;
  height: 25px;
  width: 75px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.25s ease, color 0.25s ease;
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
}

#navbar-left #input-btn:hover {
  background-color: #1141c0;
  color: #e5e5e5;
}

#navbar-right {
  display: flex;
  margin-right: 5px;
  align-items: center;
}

#navbar-right a {
  color: #1652f0;
  font-weight: bold;
}

#navbar-right a:hover {
  color: #1141c0;
  text-decoration: underline;
}

#navbar-right a:active {
  color: #1141c0;
  text-decoration: underline;
}

#navbar-right .logo-placeholder {
  font-size: 2rem;
  margin: 0;
  border-radius: 100%;
  background-color: blue;
}

.log-in {
  color: #fff !important;
  background-color: #1652f0;
  border: 2px solid #1652f0 !important;
  text-align: center;
}

.log-in:hover {
  color: #e5e5e5 !important;
  background-color: #1141c0;
  border: 2px solid #1141c0 !important;
}

.log-btn {
  font-size: 0.9em;
  text-align: center;
  border: none;
  border-radius: 10%;
  height: 20px;
  width: 45px;
  margin: 5px;
  padding-top: 2px;
  cursor: pointer;
  transition: background-color 0.25s ease, color 0.25s ease, border 0.25s ease;
}

.sign-up {
  color: #1652f0;
  background-color: #fff;
  border: 2px solid #1652f0;
  text-align: center;
}

.sign-up:hover {
  color: #1141c0;
  background-color: #e5e5e5;
}

#ticker-container {
  border: 1px solid grey;
  overflow: hidden;
  margin-bottom: 1rem;
  background-color: #e1e2e3;
}

#ticker-container a {
  text-decoration: none;
  font-size: 0.7rem;
  margin: 0.5rem 0;
}

#moving-container {
  display: flex;
  justify-content: space-between;
  transform: translateX(100%);
  animation: moving 25s linear infinite;
}

.positive,
.neutral {
  color: green;
}

.positive:hover {
  color: darkgreen;
}

.negative {
  color: red;
}

.negative:hover {
  color: darkred;
}

@keyframes moving {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

#graphs-container {
  height: 100%;
  background-color: #e7edfd;
  border: 2px solid #1652f0;
  border-radius: 1%;
  margin: 0px 5px 5px 5px;
  box-shadow: 3px 2px rgba(22, 82, 240, 0.3);
}

#graph-header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}

canvas {
  height: 100%;
  width: 100%;
  border-top: 1px solid grey;
}

#stats-name {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

#item-name {
  text-decoration: underline;
  margin: 0 5px 0 5px;
}

#graph-btn-ul {
  display: flex;
  align-items: center;
  margin: 5px 5px 0 5px;
  list-style: none;
}

#graph-btn-ul button {
  margin-top: 0;
}

.price-btn {
  background-color: #1652f0;
  color: #fff;
  border: none;
  border-radius: 10%;
  height: 25px;
  width: 75px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.25s ease, color 0.25s ease;
}

.price-btn:hover {
  background-color: #1141c0;
  color: #e5e5e5;
}

#bar-graph-container {
  height: 100%;
  background-color: #e7edfd;
  border: 2px solid #1652f0;
  border-radius: 1%;
  margin: 0px 5px 5px 5px;
  box-shadow: 3px 2px rgba(22, 82, 240, 0.3);
}

#stats-container {
  width: 96%;
  background-color: #fff;
  border: 2px solid #1652f0;
  border-radius: 1%/5%;
  margin: 0px 0px 5px 5px;
  box-shadow: 3px 2px rgba(22, 82, 240, 0.3);
}

h4 {
  text-decoration: underline;
  margin: 10px 10px 0 10px;
}

#stats-list-container {
  display: flex;
}

.stats-li p {
  margin: 0 0 10px 0;
}

.stats-li #alch-prof {
  color: green;
}

.stats-li #alch-neg {
  color: red;
}

.stats-li .item-uline {
  font-weight: bold;
  text-decoration: underline;
}

#calculator-container {
  width: 96%;
  background-color: #fff;
  border: 2px solid #1652f0;
  border-radius: 1%/5%;
  margin: 0px 0px 5px 5px;
  box-shadow: 3px 2px rgba(22, 82, 240, 0.3);
}

#calculator-container p {
  padding-left: 25px;
}

#calc-form {
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 50px;
}

#calc-form label {
  font-weight: bold;
}

#calc-form input,
#calc-form label {
  display: block;
}

#input-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

#price-container {
  width: 50%;
}

#submit-container {
  margin-left: 4em;
  width: 50%;
}

#form-overall {
  font-weight: bold;
  margin-bottom: 0;
}

#price-overall {
  margin-top: 0;
}

#input-btn {
  background-color: #1652f0;
  color: #fff;
  border: none;
  border-radius: 10%;
  height: 25px;
  width: 75px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.25s ease, color 0.25s ease;
}

#input-btn:hover {
  background-color: #1141c0;
  color: #e5e5e5;
}

#calc-alert {
  display: none;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 25px;
  border: 2px solid;
  border-radius: 5px;
  box-shadow: 2px 2px 10px grey;
  font-weight: bold;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 1;
  left: 0;
  right: 0;
}

#history-container {
  width: 96%;
  background-color: #fff;
  border: 2px solid #1652f0;
  border-radius: 1%/5%;
  margin: 0px 0px 5px 5px;
  box-shadow: 3px 2px rgba(22, 82, 240, 0.3);
}

#history-container h1 {
  text-align: center;
}

#history-container a {
  color: #1652f0;
  text-decoration: none;
}

#history-container a:hover {
  color: #1141c0;
}

#history-container a:active {
  color: #b9cbfa;
}

#history-btn-div {
  display: flex;
}

#history-btn-div #next-btn,
#history-btn-div #prev-btn {
  background-color: #1652f0;
  font-size: 0.75em;
  text-align: center;
  color: #fff;
  border: none;
  border-radius: 10%;
  height: 20px;
  width: 60px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.25s ease, color 0.25s ease;
}

#history-btn-div #next-btn:hover,
#history-btn-div #prev-btn:hover {
  background-color: #1141c0;
  color: #e5e5e5;
}

#page-text {
  font-size: 0.7em;
  font-style: italic;
  margin: 0 5px;
  color: grey;
}

#history-log-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

#history-log-container p {
  font-size: 1.2em;
}

#history-log-container p #site-name {
  font-weight: bold;
}

#history-log-container p #period-color {
  color: #1652f0;
}

#history-log-container #log-sign-button-container {
  margin: 10px auto 20px auto;
}

#history-log-container #log-sign-button-container a {
  padding: 5px 10px;
  font-size: 1.05em;
}

#history-log-container #log-sign-button-container .log-in {
  color: #fff;
}

#history-log-container #log-sign-button-container .log-in:hover {
  color: #e5e5e5;
}

table {
  margin: 25px auto 10px auto;
  border-collapse: collapse;
  width: 95%;
}

table th {
  border: 1px solid #b9cbfa;
}

table td {
  border: 1px solid #b9cbfa;
}

#page-table {
  font-size: 1.1rem;
  margin: 15px auto 25px auto;
}

#page-table tr {
  vertical-align: baseline;
}

#page-table td {
  padding: 5px 0px 5px 10px;
}

#page-table .fa-times {
  color: red;
  padding-left: 5px;
}

#page-table .fa-times:hover {
  color: #af0000;
  cursor: pointer;
}

#page-table .fa-times:active {
  color: black;
}

#sort-menu {
  position: relative;
  display: inline-block;
  margin: 5px 5px 0 2.5%;
  width: 7%;
}

#sort-menu button {
  border: none;
  height: 25px;
  width: 100%;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.75em;
}

#sort-menu .drop-btn {
  background-color: #1652f0;
  color: #fff;
}

#sort-menu #sort-content {
  display: none;
  position: absolute;
  background-color: #b9cbfa;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 100%;
}

#sort-menu #sort-content .sort-btn {
  color: #1652f0;
  background-color: #e7edfd;
  display: block;
}

#sort-menu #sort-content .sort-btn:hover {
  background-color: #b9cbfa;
}

#sort-menu #sort-content .sort-btn:active {
  background-color: #93b2ff;
}

#sort-menu:hover #sort-content {
  display: block;
}

#sort-menu:hover .drop-btn {
  background-color: #1141c0;
  color: #e5e5e5;
}

#history-container {
  margin: auto;
}

#history-container #account-button-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0 0 0;
}

#history-container #account-button-div div {
  display: flex;
}

#history-container #account-button-div button {
  width: 75px;
  height: 50px;
  margin: 0 15px;
}

#history-container #account-button-div::before {
  content: '';
  width: 30%;
  border-top: solid 1px #b9cbfa;
  margin-bottom: 20px;
}

#history-container #account-delete-window {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  height: 175px;
  width: 500px;
  background-color: #e7edfd;
  border: 3px solid #1652f0;
  border-radius: 5px;
  box-shadow: 3px 2px rgba(6, 41, 128, 0.6);
}

#history-container #account-delete-window button {
  color: #1652f0;
  background-color: #fff;
  border: 1px solid #b9cbfa;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin: 15px 5px 5px 5px;
  border-radius: 5px;
  height: 30px;
  width: 100px;
  cursor: pointer;
  transition: background-color 0.25s ease, color 0.25s ease, border 0.25s ease;
}

#history-container #account-delete-window button:hover {
  color: #1141c0;
  background-color: #e5e5e5;
  border: 1px solid #94a2c8;
}

#history-container #account-delete-window button:active {
  background-color: #94a2c8;
  border: 1px solid #9495c8;
}

#history-container #account-delete-window #account-delete-btn:hover {
  color: #ff0000;
  background-color: #e5e5e5;
  border: 1px solid #e60000;
}

#history-container #account-delete-window #account-delete-btn:active {
  color: #e60000;
  background-color: #94a2c8;
  border: 1px solid #cc0000;
}

#search-container {
  display: flex;
  margin: 15px;
}

#search-container h2 {
  margin-bottom: 0;
}

#search-container small {
  margin-left: 15px;
  color: #1141c0;
}

#query-container {
  width: 50%;
}

#item-search-ul {
  display: flex;
  flex-direction: column;
}

.item-search-li {
  display: flex;
  color: black;
  font-size: 1.2em;
  text-decoration: none;
}

.item-search-li:hover {
  color: #1141c0;
  font-size: 1.25em;
  text-decoration: underline;
  font-weight: bold;
}

.item-search-li:active {
  color: #b9cbfa;
}

#alphabet-container {
  width: 50%;
}

#alphabet-container ul {
  position: fixed;
  right: 0;
  margin-right: 20px;
}

#alphabet-container li {
  list-style: none;
}

#alphabet-container a {
  text-decoration: none;
  color: #818eaf;
}

#alphabet-container a:hover {
  color: #1141c0;
  font-weight: bold;
}

#alphabet-container a:active {
  color: #b9cbfa;
}

.log-sign-app-container {
  margin-top: 2%;
  position: relative;
  min-height: 83vh;
}

.log-sign-container {
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  margin: auto;
}

.log-sign-container h1 {
  margin: 15px;
}

.log-sign-container form {
  background-color: #1652f0;
  border-radius: 5px;
  box-shadow: 0 1px 25px -4px rgba(0, 0, 0, 0.28);
  padding: 30px 0px;
  margin-bottom: 10px;
}

.log-sign-container button {
  color: #1652f0;
  background-color: #fff;
  border: 1px solid #b9cbfa;
  text-align: center;
  font-size: 1em;
  font-weight: 500;
  text-align: center;
  border-radius: 5px;
  height: 35px;
  width: 75px;
  cursor: pointer;
  transition: background-color 0.25s ease, color 0.25s ease, border 0.25s ease;
}

.log-sign-container button:hover {
  color: #1141c0;
  background-color: #e5e5e5;
  border: 1px solid #94a2c8;
}

.log-sign-container button:active {
  background-color: #94a2c8;
  border: 1px solid #9495c8;
}

.log-sign-container input {
  width: 75%;
  height: 25px;
  margin-bottom: 15px;
  font-size: 0.9em;
  border-radius: 2px;
  border: 2px solid #e7edfd;
}

.log-sign-container input:focus {
  outline: none;
  border-color: #9ecaed;
  box-shadow: 0 0 3px #9ecaed;
}

.form-container {
  display: block;
  text-align: center;
}

.log-sign-footer {
  text-align: center;
}

.log-sign-footer a {
  margin: 0 5px;
  color: #000;
  text-decoration: none;
}

.log-sign-footer a:hover {
  color: #1141c0;
}

.log-sign-footer a:nth-child(2):before,
.log-sign-footer a:nth-child(2):after {
  content: ' ∙ ';
}

.form-input-div {
  display: flex;
  width: 90%;
  margin: auto;
}

.form-input-div input:nth-child(1) {
  margin-right: 5px;
}

.form-input-div input:nth-child(2) {
  margin-left: 5px;
}

#email-input,
textarea {
  width: 89%;
}

textarea {
  font-size: 0.9em;
  height: 100px;
  margin-bottom: 15px;
  border-radius: 2px;
  border: 2px solid #e7edfd;
}

textarea::placeholder {
  color: #b9cbfa;
}

#reset-btn {
  width: 55%;
}

#password-reset-form {
  padding-right: 15px;
  padding-left: 15px;
  width: 300px;
}

#password-reset-form p {
  font-size: 1.1em;
  font-weight: bold;
  color: #e7edfd;
  margin-top: 0 !important;
}

#password-reset-alert {
  background-color: #9dff9d;
  display: flex;
  justify-content: center;
  margin: 20px 5px 0 5px;
  height: 50px;
  border-radius: 5px;
  box-shadow: 0 1px 25px -4px rgba(0, 0, 0, 0.28);
}

select {
  width: 90% !important;
  font-size: 0.9em;
  height: 25px;
  margin-bottom: 15px;
  border-radius: 2px;
  border: 2px solid #e7edfd;
}

select:focus {
  border: 2px solid #b9cbfa;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-top: 1px solid #adadad;
  margin-bottom: 2px;
}

.footer p {
  font-size: 0.9em;
  margin: 5px 25px 0 5px;
}

.footer a {
  color: #999;
  text-decoration: none;
}

.footer a:hover {
  color: #1141c0;
}

#terms-container {
  margin: auto;
  padding-bottom: 5px;
  width: 80%;
}

#terms-container h1 {
  font-size: 1.75em;
  text-decoration: underline;
}

#terms-container a {
  color: #1652f0;
  text-decoration: none;
}

#terms-container a:hover {
  color: #1141c0;
}

#account-container h2 {
  text-align: center;
  text-decoration: underline;
  margin-bottom: 10px;
}

#account-container button {
  color: #1652f0;
  background-color: #fff;
  border: 1px solid #b9cbfa;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin: 5px;
  border-radius: 5px;
  height: 30px;
  width: 55px;
  cursor: pointer;
  transition: background-color 0.25s ease, color 0.25s ease, border 0.25s ease;
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
}

#account-container button:hover {
  color: #1141c0;
  background-color: #e5e5e5;
  border: 1px solid #94a2c8;
}

#account-container button:active {
  background-color: #94a2c8;
  border: 1px solid #9495c8;
}

#account-container #account-delete-btn:hover {
  color: #ff0000;
  background-color: #e5e5e5;
  border: 1px solid #e60000;
}

#account-container #account-delete-btn:active {
  color: #e60000;
  background-color: #94a2c8;
  border: 1px solid #cc0000;
}

#account-container a {
  text-decoration: none;
  color: #1652f0;
}

#account-page {
  display: flex;
  justify-content: center;
  margin: 0px 5%;
  font-size: 1.1em;
}

#account-page div {
  width: 100%;
}

#account-page table {
  width: 100%;
  text-align: left;
}

#account-page th {
  background-color: rgba(22, 82, 240, 0.6);
  padding: 5px;
}

#account-page td {
  background-color: #e7edfd;
  padding: 5px;
}

#account-page input {
  font-size: 0.75em;
  width: 65%;
}

#account-page #table-left {
  margin-right: 2.5%;
}

#account-page #table-right {
  margin-left: 2.5%;
}

#account-page #table-right table {
  position: relative;
}

#account-button-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0 0 0;
}

#account-button-div div {
  display: flex;
}

#account-button-div button {
  width: 75px;
  height: 50px;
  margin: 0 15px;
}

#account-button-div::before {
  content: '';
  width: 30%;
  border-top: solid 1px #b9cbfa;
  margin-bottom: 20px;
}

#account-delete-window {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 175px;
  width: 500px;
  background-color: #e7edfd;
  border: 3px solid #1652f0;
  border-radius: 5px;
  box-shadow: 3px 2px rgba(6, 41, 128, 0.6);
}

#account-delete-window button {
  width: 100px;
  margin-top: 15px;
}

#Not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#Not-found-container h1 {
  margin-bottom: 0;
}

#Not-found-container #death-rune {
  width: 20%;
  height: 20%;
}

#Not-found-container button {
  color: #fff;
  background-color: #1652f0;
  border: 1px solid #b9cbfa;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-top: 15px;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.25s ease, color 0.25s ease, border 0.25s ease;
}

#Not-found-container button:hover {
  color: #e5e5e5;
  background-color: #1141c0;
  border: 1px solid #1141c0;
}

@media screen and (max-width: 1100px) {
  #item-page-container {
    flex-direction: column;
  }
  #graph-container {
    width: 100%;
    height: 100%;
  }
  #stats-cal-container {
    width: 100% !important;
    margin-bottom: 15px;
  }
  #stats-cal-mediaQ {
    display: flex !important;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-right: 5px;
  }
  #moving-container a {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
}

@media screen and (min-width: 806px) and (max-width: 1099px) {
  #stats-container {
    width: 50% !important;
  }
  #calculator-container {
    width: 50% !important;
  }
  #history-container {
    width: 98.5% !important;
    margin: auto !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 805px) {
  #stats-cal-mediaQ {
    flex-direction: column;
    margin-bottom: 0px;
  }
  #stats-container {
    margin-right: auto !important;
    margin-left: auto !important;
    width: 98% !important;
  }
  #calculator-container {
    margin-right: auto !important;
    margin-left: auto !important;
    width: 98% !important;
  }
  #history-container {
    margin-right: auto !important;
    margin-left: auto !important;
    width: 98% !important;
  }
  #account-page {
    flex-direction: column;
  }
  #account-page #table-right {
    margin-left: 0px !important;
  }
  #account-page-footer-fix {
    padding-bottom: 50px;
  }
  .footer {
    position: relative !important;
  }
  .log-footer {
    position: fixed !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 700px) {
  #moving-container a {
    margin: 0px 10px !important;
  }
  #sort-menu {
    width: 10% !important;
  }
  #refresh-span-true {
    width: 50%;
  }
}

@media screen and (min-width: 0px) and (max-width: 501px) {
  header {
    margin-bottom: 15px;
    border-bottom: 1px solid gray;
  }
  #navbar-left #logo {
    height: 1em !important;
    width: 1em !important;
  }
  #navbar-left input {
    width: 50%;
  }
  #navbar-left #input-btn {
    height: 20px;
    width: 20px;
    text-align: center !important;
    padding: auto !important;
  }
  #navbar-left .fa-search {
    margin: auto;
  }
  #navbar-left #search-text {
    display: none;
  }
  #navbar-right #account-name-link {
    display: none;
  }
  #navbar-right i {
    font-size: 25px;
  }
  #ticker-container {
    display: none;
  }
  #graph-header {
    flex-direction: column;
  }
  #graph-header #stats-btns {
    margin-top: 5px;
  }
  #graph-header #stats-btns ul {
    padding-left: 0;
  }
  #sort-menu {
    width: 13% !important;
  }
  #sort-menu .drop-btn,
  #sort-menu #sort-content {
    width: 75px;
  }
  .table-date {
    display: none;
  }
  #account-container button {
    text-align: center !important;
  }
  #account-delete-window {
    width: 400px !important;
  }
  #account-button-div button {
    width: 100px;
  }
  #refresh-span-true {
    width: 70%;
  }
  .log-sign-container {
    width: 80%;
  }
  .log-sign-container button {
    width: 100px;
  }
}

@media screen and (min-width: 0px) and (max-width: 415px) {
  #calc-form {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  #account-delete-window {
    width: 300px !important;
  }
  #refresh-span-true {
    width: 85%;
  }
}
